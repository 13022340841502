:where(.typography::selection) {
  background-color: var(--color-secondary-700);
  color: var(--color-championship-white);
}

:where(.display1) {
  font-size: 48px;
  line-height: 56px;
  letter-spacing: -2px;
  font-family: var(--font-family-system);
  font-weight: var(--font-weight-xbold);

  @media (--tablet) {
    font-size: 60px;
    line-height: 68px;
    letter-spacing: -2.5px;
  }
}

:where(.display2) {
  font-size: 48px;
  line-height: 56px;
  letter-spacing: -2px;
  font-family: var(--font-family-system);
  font-weight: var(--font-weight-bold);

  @media (--tablet) {
    font-size: 60px;
    line-height: 68px;
    letter-spacing: -2.5px;
  }
}

/*
  Heading
*/
:where(.h1, .desktop-h1) {
  font-size: 48px;
  line-height: 56px;
  letter-spacing: -2px;
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-system);
}

:where(.h2, .desktop-h2) {
  font-size: 32px;
  letter-spacing: -0.5px;
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-system);
}

:where(.h3, .desktop-h3) {
  font-size: 28px;
  letter-spacing: -0.5px;
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-system);
}

:where(.h4, .desktop-h4) {
  font-size: 24px;
  letter-spacing: -0.5px;
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-system);
}

:where(.h5, .desktop-h5) {
  font-size: 20px;
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-system);
}

:where(.h6, .desktop-h6) {
  font-size: 16px;
  line-height: normal;
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-system);
}

/*
  Sub-Heading
*/
:where(.subheading1) {
  font-size: 14px;
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-system);
}

:where(.subheading2) {
  font-size: 16px;
  font-weight: var(--font-weight-medium);
  font-family: var(--font-family-system);
}

:where(.subheading3) {
  font-size: 14px;
  font-weight: var(--font-weight-medium);
  font-family: var(--font-family-system);
  line-height: normal;
}

:where(.subheading4) {
  font-size: 16px;
  font-weight: var(--font-weight-regular);
  font-family: var(--font-family-system);
}

/*
  Body
*/
:where(.body1) {
  font-size: 16px;
  line-height: 26px;
  font-weight: var(--font-weight-medium);
  font-family: var(--font-family-system);
}

:where(.body2) {
  font-size: 14px;
  line-height: 22px;
  font-weight: var(--font-weight-medium);
  font-family: var(--font-family-system);
}

:where(.body3) {
  font-size: 16px;
  line-height: 26px;
  font-weight: var(--font-weight-regular);
  font-family: var(--font-family-system);
}

:where(.body4) {
  font-size: 14px;
  line-height: 22px;
  font-weight: var(--font-weight-regular);
  font-family: var(--font-family-system);
}

/*
  Caption
*/
:where(.caption1) {
  font-size: 12px;
  font-weight: var(--font-weight-medium);
  font-family: var(--font-family-system);
}

:where(.caption2) {
  font-size: 12px;
  line-height: 18px;
  font-weight: var(--font-weight-regular);
  font-family: var(--font-family-system);
}

:where(.caption3) {
  font-size: 10px;
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-system);
}

:where(.caption4) {
  font-size: 10px;
  font-weight: var(--font-weight-medium);
  font-family: var(--font-family-system);
}

:where(.caption5) {
  font-size: 10px;
  font-weight: var(--font-weight-regular);
  font-family: var(--font-family-system);
}

/*
  Overline
*/
:where(.overline1) {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: var(--font-weight-medium);
  font-family: var(--font-family-system);
}

:where(.overline2) {
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: var(--font-weight-medium);
  font-family: var(--font-family-system);
}

/*
  Button
*/
:where(.button1) {
  font-size: 16px;
  font-weight: var(--font-weight-bold);
  letter-spacing: 0.2px;
  font-family: var(--font-family-system);
}

:where(.button2) {
  font-size: 14px;
  font-weight: var(--font-weight-bold);
  letter-spacing: 0.2px;
  font-family: var(--font-family-system);
}

:where(.button3) {
  font-size: 12px;
  font-weight: var(--font-weight-bold);
  letter-spacing: 0.2px;
  font-family: var(--font-family-system);
}

:where(.buttonLink1) {
  font-size: 16px;
  font-weight: var(--font-weight-bold);
  letter-spacing: 0.2px;
  font-family: var(--font-family-system);
  text-decoration: underline;
}

:where(.buttonLink2) {
  font-size: 14px;
  font-weight: var(--font-weight-bold);
  letter-spacing: 0.2px;
  font-family: var(--font-family-system);
  text-decoration: underline;
}

:where(.buttonLink3) {
  font-size: 12px;
  font-weight: var(--font-weight-bold);
  letter-spacing: 0.2px;
  font-family: var(--font-family-system);
  text-decoration: underline;
}

/*
  Mobile
*/
@media screen and (max-width: 768px) {
  :where(.h1, .mobile-h1) {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.5px;
  }

  :where(.h2, .mobile-h2) {
    font-size: 28px;
  }

  :where(.h3, .mobile-h3) {
    font-size: 24px;
  }

  :where(.h4, .mobile-h4) {
    letter-spacing: 0;
    font-size: 20px;
  }

  :where(.h5, .mobile-h5) {
    font-size: 16px;
  }

  :where(.h6, .mobile-h6) {
    font-size: 16px;
    letter-spacing: normal;
    line-height: normal;
  }
}
